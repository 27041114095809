/* 
// Buttons
*/

.sidebar {
  .btn {
    &-lg {
      @include media-breakpoint-down(md) {
        padding: rem(10px);
      }
    }
  }
}

.btn-sm {
  font-size: 12px;

  &.rounded-circle {
    width: 35px;
    height: 35px;
    font-size: 18px;
    text-align: center;
    padding: 0px;
    line-height: 35px;
  }
}

.btn-xs{
  height: 20px;
  padding: 0px 15px !important;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-with-icon {
  img {
    height: 30px;
    margin-right: 5px;
  }
  &.btn-lg {
    [class*="icon-"] {
      font-size: 24px;
      vertical-align: text-bottom;
      [class*="path"].text-white {
        &:before {
          color: white;
        }
      }
    }
  }
}
.btn-with-icon-circle {
  font-size: 11px;
  font-weight: 600;
  color: $primary;
  position: relative;
  padding-left: 35px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  i {
    width: 30px;
    height: 30px;
    display: flex;
    position: absolute;
    left: -2px;
    top: -2.5px;
    background: white;
    border-radius: 25px;
    border: 1px solid $pacific;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: $primary;
  }
}
