/*Search Block*/

// .srch-block {
// 	position: relative;
// 	text-align: center;

// 	h2 {
// 		font-family: "Play";
// 		font-weight: bold;
// 		font-size: rem(58px);
// 		color: #0e477a;
// 		line-height: rem(68px);
// 		word-wrap: break-word;

// 		@include media-breakpoint-down(sm) {
// 			font-size: rem(36px);
// 		}
// 	}
// 	&.subpage-srch {
// 		margin-top: 91px;
// 	}

// 	&.home-banner {
// 		h2 {
// 			margin-top: 70px;
// 		}
// 	}
// }
.srch-bar {
  margin: 0 auto;
  max-width: 1125px;
  padding-top: rem(50px);
}
.search-option {
  border: 1px solid $secondary;
  border-left: 0px;
  border-right: 1px solid $secondary;

  &:first-of-type {
    // border-right: 0px;
    border-left: 1px solid $secondary;
    border-radius: 4px 0px 0px 4px;
    .vs__dropdown-toggle {
      border-radius: 4px 0px 0px 4px;
    }
  }
  &:last-of-type {
    border-right: 1px solid $secondary;
    border-radius: 0px 4px 4px 0px;
  }
  .vs__dropdown-toggle {
    border: 0px;
    padding: 0px;
    span.vs__selected {
      position: absolute;
      font-size: 13px;
      line-height: 41px;
      margin-top: 0px;
      border: 0px;
      margin-bottom: 0px;
    }
    .vs__actions {
      padding: 4px 10px 0 3px;
    }
  }
  .vs__search,
  .vs__search:focus {
    margin: 0px;
    font-size: 13px;
    line-height: 41px;
    border: 0px;
  }

  input {
    font-family: "Opensans";
    font-weight: normal;
    font-size: 13px;
    // height: 41px;
    line-height: 41px;
    display: block;
    text-align: left;
    color: $secondary;
    width: 100%;
    border: none;
    // background: $white;
    padding: 0px 0px 0px 10px;
    // &.valid {
    //   background: white;
    // }
  }

  input[type="submit"],
  input[type="button"] {
    font-family: "Play";
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    background: $secondary;
    color: $white;
    text-align: center;
    &:hover {
      background: lighten($secondary, 9);
    }
  }
  // &:first-child{
  //     input{
  //         border-radius: rem(8px) 0 0 rem(8px);
  //         @include media-breakpoint-down(sm){
  //             border-radius: 0px;
  //         }
  //     }
  // }
  // &:last-child{
  //     input{
  //         border-radius: 0px rem(8px) rem(8px) 0px;
  //         @include media-breakpoint-down(sm){
  //             border-radius: 0px;
  //         }
  //     }
  // }
}

@media all and (max-width: 1254px) {
  .srch-block-1 {
    input[type="submit"],
    input[type="button"] {
      font-size: 14px;
      padding-left: 0px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .search-option {
    border-left: 1px solid $secondary;
    border-radius: 4px;
    margin-bottom: 10px;
    .vs__dropdown-toggle {
      span.vs__selected {
        line-height: 30px;
      }
    }
    .vs__search,
    .vs__search:focus {
      line-height: 30px;
    }
    &:last-of-type {
      input[type="submit"],
      input[type="button"] {
        border-radius: 4px;
      }
    }
    &:first-of-type {
      border-radius: 4px 4px 4px 4px;
    }
    &:last-of-type {
      border: 0px;
    }
  }
  .srch-block-1 {
    input[type="submit"],
    input[type="button"] {
      height: 30px !important;
      line-height: 30px;
      font-size: 14px;
    }
  }
}

/*.main-bg01 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
}*/
.home-banner {
  background: url(./assets/images/adult-blood-control.jpg) no-repeat top center / cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  height: 480px;
}
.homeSlider {
  .slider-img {
    min-height: 450px;
  }
  .carousel-caption {
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);
    padding-top: 0px;
    h2 {
      padding-top: 150px;
      font-size: 70px;
      @media all and (max-width: 1058px) {
        font-size: 60px;
      }
      @media all and (max-width: 992px) {
        font-size: 50px;
      }
      @media all and (max-width: 768px) {
        font-size: 30px;
        padding-top: 200px;
      }
    }
    @media all and (max-width: 768px) {
      .btn {
        font-size: 13px;
      }
    }
  }
  .homeSlider-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
  }
  .carousel-control-prev-icon {
    background-image: none;
    display: inline-block;
    line-height: 1;
    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 44px;
    &:before {
      content: "\e901";
    }
    @media all and (max-width: 640px) {
      font-size: 30px;
      margin-right: 20px;
    }
  }
  .carousel-control-next-icon {
    background-image: none;
    display: inline-block;
    line-height: 1;
    font-family: "icomoon";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 44px;
    &:before {
      content: "\e902";
    }
    @media all and (max-width: 640px) {
      font-size: 30px;
    }
  }
}

.homeCover {
  position: relative;
  .srch-block {
    position: absolute;
    z-index: 20;
    left: 50%;
    transform: translateX(-50%);
    top: 90px;
  }
  .search-option {
    border: 1px solid $white;
    border-right: 1px solid $white;
    border-left: 0px;
    &:first-of-type {
      // border-right: 0px;
      border-left: 1px solid $white;
    }
    &:last-of-type {
      border: 0px;
      input[type="button"] {
        height: 43px;
        border-radius: 0 4px 4px 0;
      }
    }
    .vs--single.vs--open .vs__selected {
      opacity: 1;
    }
    .vs__dropdown-toggle {
      border: 0px;
      padding: 0px;
      span.vs__selected {
        position: absolute;
        font-size: 13px;
        line-height: 41px;
        margin-top: 0px;
        border: 0px;
        margin-bottom: 0px;
        color: white;
      }
    }
    .vs__search,
    .vs__search:focus {
      margin: 0px;
      font-size: 13px;
      line-height: 41px;
      border: 0px;
    }
    .vs__open-indicator {
      fill: white;
    }
    input {
      color: $white;
      background: transparent;
      &::placeholder {
        color: $white;
      }
    }
    input[type="submit"],
    input[type="button"] {
      background: $white;
      color: $secondary;
      &:hover {
        background: darken($white, 8);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .search-option {
      border-left: 1px solid #fff;
      border-radius: 4px;
      margin-bottom: 10px;
      .vs__dropdown-toggle {
        span.vs__selected {
          line-height: 30px;
        }
      }
      .vs__search,
      .vs__search:focus {
        line-height: 30px;
      }
      &:last-of-type {
        input[type="submit"],
        input[type="button"] {
          border-radius: 4px;
        }
      }
    }
    .srch-block {
      top: 50px;
    }
    input[type="submit"],
    input[type="button"] {
      height: 30px !important;
      line-height: 30px;
      font-size: 14px;
    }
    &:first-of-type {
      .vs__dropdown-toggle {
        border-radius: 4px 4px 4px 4px;
      }
    }
    &:last-of-type {
      border-radius: 4px 4px 4px 4px;
    }
  }
}
// search autocomplete dropdown styles
.ui-widget {
  &.ui-widget-content {
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    .ui-menu-item {
      margin-bottom: 5px;
      div {
        line-height: 1em;
      }
    }
  }
}

.after-header {
  margin-top: rem(110px);
}

.blood-cover {
  background-image: url("./assets/images/blood-cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
