.datePickerInput {
  width: 0px;
  height: 0px;
  padding: 0px;
  font-size: 0px;
  border: 0px;
  line-height: 0;
  opacity: 0px;
  position: absolute;
  z-index: -1;
  visibility: hidden;
}

.without-input-field > .form-control:not(:last-child) {
  flex: 0;
}

.span06 {
  display: block;
  text-align: center;
  padding-bottom: 1em;
  margin: auto;
}
.span06 i {
  font-size: 1.5em;
  color: #fff;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top::after {
  display: none;
}

.appointment-calendar {
  .vdp-datepicker__calendar {
    width: 100% !important;
  }
}
