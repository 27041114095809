.featured-service {
  position: relative;
  .featured-service-details {
    position: absolute;
    bottom: 0;
    min-height: 339px;
    display: flex;
    flex-direction: column;
  }
  @media all and (max-width: 640px) {
    .img-fluid {
      display: none;
    }
    .featured-service-details {
      position: static;
      bottom: unset;
      text-align: center;
      min-height: unset;
    }
  }
}
