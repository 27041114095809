/* 
// Inputs
*/
.form-control {
  background: #fff;
  font-family: "Opensans";
  color: $secondary;
  width: 100%;
  border: 1px solid $tartiary;
  &:focus {
    border-color: #95989a;
    // box-shadow: 0 0 0 0 transparent;
  }
  &.focused {
    border-color: #95989a;
  }
}

input + span.text-danger,
select + span.text-danger {
  position: relative;
  // top: -22px;
  font-size: rem(14px);
}
.form-control::-moz-placeholder {
  color: $secondary;
  opacity: 1;
}
.form-control::-webkit-input-placeholder {
  color: $secondary;
}

// Checkbox
.custom-checkbox {
  .custom-control-label::before {
    border-radius: 3px;
    background-color: white;
    border: 1px solid #95989a;
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0px transparent;
}

.intl-tel-input {
  width: 100%;
  margin-bottom: 0px !important;
}
// Datepicker field

.dateField {
  width: 100%;
  color: #bfc1c2;
  background: #fdfdfe;
  border: 1px solid $tartiary;
  border-radius: 5px;
  i {
    font-size: 1.25em;
    color: #95989a;
    display: flex;
    align-items: center;
  }
}
// Kendo datepicker css
.k-autocomplete.k-state-default,
.k-dropdown-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-picker-wrap.k-state-default {
  border-color: $tartiary !important;
}

// Old Codes
.inp02 {
  background: #fdfdfe;
  border: 1px solid $secondary;
  margin-bottom: 1em;
}
.inp03 {
  text-align: center;
}
.inp04 {
  background: none;
  border: none;
  width: 90%;
  margin: 0;
}
