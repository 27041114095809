.feture-wrap {
  margin: 0 auto;
  max-width: 1920px;
}
// .ft-block1 {
//   background: #04f0fc;
// }
// .ft-block2 {
//   background: #0e477a;
// }
// .ft-block3 {
//   background: #04f0fc;
// }
// .ft-block {
//   width: 33.333%;
//   text-align: center;
//   color: #fff;
//   box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
//   padding: rem(30px) 0 rem(60px) 0;
// }
// .ft-block i {
//   display: block;
// }
// .ft-block i img {
//   width: auto;
//   height: rem(107px);
//   margin-bottom: 1.125em;
// }
// .ft-block h3 {
//   font-family: "Play";
//   font-weight: bold;
//   font-size: rem(38px);
//   color: #0e477a;
//   text-transform: uppercase;
//   line-height: 1.1666em;
//   margin: 0 0 rem(25px) 0;
// }
// .ft-block2 h3 {
//   color: #fff;
// }
// .ft-block p {
//   font-size: rem(18px);
//   line-height: 1.3em;
//   padding: 0 3.5em;
//   margin: 0 0 1.125em 0;
// }
// .ft-block span {
//   display: block;
// }
// .ft-block span a {
//   display: inline-block;
//   font-family: "Play";
//   font-weight: bold;
//   font-size: 1.5em;
//   color: #fff;
//   text-transform: uppercase;
//   line-height: 2.6666em;
//   border-radius: 5px;
//   border: 3px solid #fff;
//   padding: 0 rem(70px);
// }

.feture-nav {
  justify-content: center;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 2px;
    background: $info;
    position: absolute;
    top: 50%;
    z-index: -1;
  }
  li {
    margin-right: 40px;
    z-index: 1;
    position: relative;
    a.nav-link {
      display: block;
      font-family: "Play";
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      color: #484848;
      border-radius: 36px;
      line-height: 1;
      padding: 12px 30px;
      border: 1px solid $info;
      background: white;
      position: relative;

      &.active,
      &:hover {
        color: #fff;
        background-color: #04f0fc;
      }
      &.active:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-width: 12px 12px 0 12px;
        border-color: $info transparent transparent transparent;
        border-style: solid;
      }
      @include media-breakpoint-down(sm) {
        font-size: 12px;
        padding: 8px 15px;
        &.active:after {
          border-width: 8px 8px 0 8px;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      margin: 0 10px 0 0;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  @media all and (max-width: 460px) {
    justify-content: start;
    padding-left: 15px;
    &:before {
      display: none;
    }
    li {
      margin: 0px;
      a.nav-link {
        border-radius: 0px;
        padding: 5px 5px;
        font-size: 10px;
      }
    }
  }
}
.feature-tab-content {
  padding-top: 50px;
  @include media-breakpoint-down(md) {
    padding-top: 30px;
  }
}

.featured-doctor-list {
  .featured-image {
    height: 247px;
    width: 247px;
    border-radius: 247px;
    overflow: hidden;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $pacific;
    margin: 0 auto;
    .featured-image-circle{
      height: 237px;
      width: 237px;
      border-radius: 237px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      .img-fluid {        
        
        // width: 100%;
        &::after{
          content: "\e919"" " attr(alt);
          font-family: "icomoon";
          font-size: 16px;
          color: rgb(100, 100, 100);
          display: flex;
          align-items: center;
          justify-content: center;
          background: $gray;
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 100%;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.featured-listing-featured {
  position: relative;
  .featured-item-details {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
