.favorite-check {
	input[type="checkbox"] {
		width: 0px;
		height: 0px;
		visibility: hidden;
		opacity: 0;
		& ~ label {
			font-size: 22px;
			color: $danger;
			cursor: pointer;
			&:before {
				content: "\E917";
				font-family: "icomoon" !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
			}
		}
		&:checked {
			& ~ label {
				&:before {
					content: "\E918";
					font-family: "icomoon" !important;
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
				}
			}
		}
	}
}
