.donor-main-slider{
    &.owl-theme{
        .owl-dots{
            .owl-dot{
                span{
                    width: 25px;
                    height: 25px;
                    margin: 5px 10px;
                }
                &.active{
                    span{
                        background: #444;
                    }
                }
                &:hover{
                    span{
                       background: #444; 
                    }
                }
            }
        } 
    } 
}