.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    max-width: 100%;
    font-size: 1.25em;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}


/* style 6 */

.inputfile-6 + label {
	display:flex;
	font-family: 'Play-Regular';
    border:none;
    background-color: none;
    padding: 0;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
    border-color: #722040;
}

.inputfile-6 + label span,
.inputfile-6 + label strong {
    padding:0;
    /* 10px 20px */
}

.inputfile-6 + label span {
    width: 410px;
    min-height: 2.5em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
	margin-right: 1em;
	background: #fff;
	border: 1px solid #95989A;
	border-radius: 5px;
}

.inputfile-6 + label strong {
	width:170px;
    height:2.5em;
	line-height:2.5em;
    color: #fff;
	text-align:center;
    background-color: #484848;
    display: inline-block;
	border-radius: 5px;
	font-weight: normal;	
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: #484848;
}

@media screen and (max-width: 50em) {
	.inputfile-6 + label strong {
		display: block;
	}
}
