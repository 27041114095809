/* subpage */
.subpage .ms-hdr .navbar{
	background:rgba(255,255,255,1);
}
.subpage-srch{
	background:#484848;
	padding: rem(20px) 0;
	border:1px solid #95989A;
}
.subpage-srch .srch-bar{
	padding-top:0;
}
.subpage-srch .srch-bar .sel-loc,.subpage-srch .srch-bar .srch-btn01{
	border-radius:0;
}
.subpage-col1{
	max-width:36%;
}
/* .sidebar{
	margin-right:4.375em;
} */
.subpage-col2{
	max-width:64%;
}