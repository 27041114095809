/* 
    Form Styling
*/

form {
  p {
    &.valid {
      color: $success;
    }
  }
}
select.form-control:not([size]):not([multiple]) {
  height: 32px;
  padding: 5px 20px;
  &.form-control-sm {
    padding: 2px 10px;
    height: 24px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
