/* 
Typography
*/
@font-face {
  font-family: "Play";
  src: url("./assets/fonts/play-regular.eot");
  src: url("./assets/fonts/play-regular.eot") format("embedded-opentype"), url("./assets/fonts/play-regular.woff2") format("woff2"),
    url("./assets/fonts/play-regular.woff") format("woff"), url("./assets/fonts/play-regular.ttf") format("truetype"),
    url("./assets/fonts/play-regular.svg") format("svg");
  font-weight: 400;
}
@font-face {
  font-family: "Play";
  src: url("./assets/fonts/play-bold.eot");
  src: url("./assets/fonts/play-bold.eot") format("embedded-opentype"), url("./assets/fonts/play-bold.woff2") format("woff2"),
    url("./assets/fonts/play-bold.woff") format("woff"), url("./assets/fonts/play-bold.ttf") format("truetype"),
    url("./assets/fonts/play-bold.svg") format("svg");
  font-weight: bold;
}
@font-face {
  font-family: "Opensans";
  src: url("./assets/fonts/opensans-regular.eot");
  src: url("./assets/fonts/opensans-regular.eot") format("embedded-opentype"),
    url("./assets/fonts/opensans-regular.woff2") format("woff2"), url("./assets/fonts/opensans-regular.woff") format("woff"),
    url("./assets/fonts/opensans-regular.ttf") format("truetype"), url("./assets/fonts/opensans-regular.svg") format("svg");
  font-weight: normal;
}
@font-face {
  font-family: "Opensans";
  src: url("./assets/fonts/opensans-light.eot");
  src: url("./assets/fonts/opensans-light.eot") format("embedded-opentype"), url("./assets/fonts/opensans-light.woff2") format("woff2"),
    url("./assets/fonts/opensans-light.woff") format("woff"), url("./assets/fonts/opensans-light.ttf") format("truetype"),
    url("./assets/fonts/opensans-light.svg") format("svg");
  font-weight: 300;
}
@font-face {
  font-family: "Opensans";
  src: url("./assets/fonts/opensans-bold.eot");
  src: url("./assets/fonts/opensans-bold.eot") format("embedded-opentype"), url("./assets/fonts/opensans-bold.woff2") format("woff2"),
    url("./assets/fonts/opensans-bold.woff") format("woff"), url("./assets/fonts/opensans-bold.ttf") format("truetype"),
    url("./assets/fonts/opensans-bold.svg") format("svg");
  font-weight: bold;
}
body {
  background: #fff;
  font-family: "Opensans";
  font-weight: normal;
  font-size: 16px;
  color: #484848;
  padding: 0 0 0 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  background: none;
  line-height: inherit;
  padding: 0;
  margin: 0;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
a:hover,
a:focus,
input:focus,
button:focus {
  text-decoration: none !important;
  outline: 0 !important;
}
.text-thin {
  font-family: "Opensans";
  font-weight: normal;
  color: #95989a;
}

.heading01 {
  margin-bottom: rem(25px);
  color: #04f0fc;
  span {
    display: block;
    width: 3.125em;
    display: flex;
    align-items: center;
    i {
      font-size: rem(35px);
    }
  }
  h3 {
    font-size: rem(35px);
    font-family: "Play";
    font-weight: bold;
    line-height: 1em;
  }
}
.span04 {
  display: block;
  font-size: rem(20px);
  font-family: "Opensans";
  font-weight: bold;
  line-height: rem(26px);
  margin-bottom: rem(25px);
}

.h2-01 {
  font-family: "Play";
  font-weight: bold;
  font-size: rem(60px);
  color: #0e477a;
  text-align: center;
  line-height: 1.166em;
  word-wrap: break-word;
  margin: 1.25em 0 0.8333em 0;

  @include media-breakpoint-down(sm) {
    font-size: rem(30px);
  }
}

.h3-02 {
  font-family: "Play";
  font-weight: bold;
  font-size: rem(25px);
  line-height: 1.1562em;
  margin-bottom: 1em;
}
.h-txt1 {
  color: #343d3d;
}
.h-txt1 i {
  font-size: 1.5em;
}
.h4-04 {
  font-size: rem(20px);
  margin-bottom: rem(10px);
}
.h3-01 {
  font-family: "Play";
  font-size: rem(26px);
  color: #04f0fc;
  font-weight: 900;
  line-height: 1.1562em;
}

.h4-01 {
  background: #484848;
  text-align: center;
  font-family: "Play";
  font-size: rem(22px);
  color: #fff;
  font-weight: bold;
  line-height: 1.125em;
  border-top-left-radius: 0.4166em;
  border-top-right-radius: 0.4166em;
  height: 2.9166em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.h3-03 {
  font-family: "Play";
  font-weight: bold;
  font-size: 3.125em;
  color: #04f0fc;
  text-align: center;
  line-height: 1.16em;
  margin: 1em 0 0 0;
}
.p-02 {
  font-size: 1.25em;
  margin: 1.5em 0 0.625em 0;
}
.p-03 {
  font-size: rem(20px);
  line-height: rem(26px);
  margin-bottom: rem(10px);
}

.b-01 {
  display: block;
  font-size: 2.625em;
  font-weight: bold;
  line-height: 0.9523em;
}
.p-04,
.p-05 {
  text-align: center;
  font-size: 1.25em;
  color: #95989a;
  margin-bottom: 2em;
}
.p-05 {
  text-align: center;
  margin-bottom: 4em;
}
.p-06 {
  font-size: 1.25em;
  margin-bottom: 1em;
}
/* .p-07{
	color:#95989A;
}
.p-08{
	background:#fff;
	text-align:center;
	padding:1em 0;
	margin:0 0 1.25em 0;
} */

.span08 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5em auto;
  background: #fff;
  width: 11.375em;
  height: 11.375em;
  border: 0.625em solid #95989a;
  border-radius: 100px;
  margin-bottom: 1em;
}
.span08 i {
  font-size: 6em;
  color: #f7d801;
}
.span08 i.fa-heart {
  color: #d75a4a;
}
/* .span09{
	display: block;
	font-size:2.375em;
	color:#04F0FC;
} */

.text-black-50 {
  color: #95989a !important;
}

.playBold {
  font-family: "Play";
  font-weight: bold;
}
.OpenSansBold {
  font-family: "Opensans";
  font-weight: bold;
}

.play {
  font-family: "Play";
}
$openSansBold: "Opensans";

.font-weight-semibold {
  font-weight: 500;
}
.font-size-sm {
  font-size: $font-size-sm; // 12px
}
.font-size-lg {
  font-size: $font-size-lg; //18px
}
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 13px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-38 {
  font-size: 38px !important;
}
.fs-48 {
  font-size: 48px !important;
}

.fs-72 {
  font-size: 72px !important;
}

.lh-30px {
  line-height: 30px !important;
}
.lh-1em {
  line-height: 1em !important;
}

.text-underline {
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}
