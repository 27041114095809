.rounded-chevron-nav {
  &.owl-nav {
    button {
      &.owl-prev,
      &.owl-next {
        padding: 0;
        width: 60px;
        height: 60px;
        border: 0px;
        border-radius: 100%;
        font-size: 25px;
        color: #444;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(255, 255, 255, 0.5);
        &:hover {
          background: rgba(200, 200, 200, 0.6);
        }
      }
      &.owl-prev {
        left: 60px;
      }
      &.owl-next {
        right: 60px;
      }
    }
  }
}

.walkthroughSlider {
  .carousel-caption {
    color: $secondary;
    bottom: 50px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    color: $secondary;
    font-size: 40px;
  }
  .carousel-control-prev-icon {
    background-image: none;
    width: 0px;
    height: 0px;
    transform: translateY(-30px);
    &:before {
      content: "\e90a";
      font-family: "icomoon" !important;
    }
  }
  .carousel-control-next-icon {
    background-image: none;
    width: 0px;
    height: 0px;
    transform: translateY(-30px);
    &:before {
      content: "\e90b";
      font-family: "icomoon" !important;
    }
  }
  .carousel-indicators {
    margin-bottom: 30px;
    li {
      width: 12px;
      height: 12px;
      border-radius: 52px;
      border-top: 0px;
      border-bottom: 0px;
      box-sizing: border-box;
      background-color: $pacific;
      margin-right: 20px;
      margin-left: 20px;
      opacity: 1;
      &.active {
        background-color: $danger;
      }
      &:focus {
        outline: 0px;
      }
    }
  }
}
