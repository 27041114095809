/* Doctor Details */
.srch-block .h2-02 {
	font-family: "Play-Bold";
	font-size: rem(40px);
	color: #04f0fc;
	text-transform: uppercase;
	line-height: 1em;
	word-wrap: break-word;
}
.doctor-details {
	padding: 3.5em 0 3em 0;
}
.doctor-profile-widget {
	text-align: center;
	padding: 20px 10px;
	// margin-bottom: 2.5rem;
	&.p-5 {
		@include media-breakpoint-down(sm) {
			padding: rem(20px) !important;
		}
	}
}

.give-ratings {
	border: 1px solid #95989a;
	border-bottom-left-radius: 0.625em;
	border-bottom-right-radius: 0.625em;
}
.give-ratings .rat-favShow {
	font-size: rem(35px);
	margin: 1em 0;
	text-align: center;
}

.border01 {
	display: block;
	border-bottom: 1px solid #e1e6f0;
	// margin-bottom: 2.5em;
}
