// User profile widget styles
.user-ProPic {
  margin: 0 auto;
  background: #fff;
  width: 187px;
  height: 187px;
  text-align: center;
  border-radius: 200px;
  border: 2px solid $gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  .thumb {
    display: flex;
    align-items: stretch;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    height: 100%;
  }
  .propic-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: $gray;
    overflow: hidden;
    img.profile-photo {
      &:after {
        content: "\e919"" " attr(alt);
        font-family: "icomoon";
        font-size: 16px;
        color: rgb(100, 100, 100);
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gray;
        position: absolute;
        top: 10px;
        left: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        border-radius: 100%;
      }
    }
    img {
      width: 100%;
    }
  }
  div {
    a {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: white;
        font-size: 80px;
      }
    }
    &:hover {
      a {
        width: 100%;
        height: 100%;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.doc-listing-thumb button {
    bottom: -5px;
    font-weight: bold;
  }
}
.circle-profile {
  overflow: hidden;
  border: 10px solid $blue;
  img {
    width: 100%;
  }
}
// .profile-badge-1 {
// 	position: absolute;
// 	left: -20px;
// 	bottom: 10px;
// 	border: 6px solid white;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// 	flex-direction: column;
// 	padding: 0px 15px;
// 	h2 {
// 		line-height: 1;
// 		font-family: "Opensans-Bold", sans-serif;
// 		margin-bottom: 8px;
// 	}
// 	span {
// 		font-size: 12px;
// 		line-height: 1;
// 	}
// }
// .profile-badge-2 {
// 	position: absolute;
// 	right: -20px;
// 	bottom: 10px;
// 	border: 6px solid white;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .up-badge-1 {
// 	position: absolute;
// 	left: 25px;
// 	bottom: -10px;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// }
// .up-badge-2 {
// 	position: absolute;
// 	right: 25px;
// 	bottom: -10px;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// }
// .up-badge-3 {
// 	position: absolute;
// 	left: -16px;
// 	bottom: 60px;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// }
// .up-badge-4 {
// 	position: absolute;
// 	right: -16px;
// 	bottom: 60px;
// 	display: inline-flex;
// 	align-items: center;
// 	justify-content: center;
// }

.user-thumb-sm {
  display: inline-flex;
  overflow: hidden;
  border: 1px solid $primary;
  justify-content: center;
  span {
    line-height: 26px;
    font-weight: bold;
    color: white;
    font-size: 14px;
  }
}
.profile-badges {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 48px;
    margin: 0 5px;
    i[class^="icon-"] {
      font-size: 16px;
    }
  }
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}
