/* 
// cards
*/

.card {
  &-header {
    background-color: white;
  }

  &.card-time {
    border-width: 1px;
    border-color: $info;
    border-radius: 5px;
    cursor: pointer;
    &.at-chamber {
      margin-right: 30px;
      min-width: 164px;
      margin-bottom: 20px;
    }
    &.disabled{
      cursor: initial;
      border-color: $gray;
      background-color: $gray;
    }
    .card-header {
      padding: 4px 8px;
      border-bottom-width: 1px;
      border-color: $info;
      align-items: center;
    }
    .card-body {
      padding: 8px;
    }
  }
}

.time-slot-container {
  & > ul {
    max-height: 360px;
    overflow-y: auto;
    justify-content: flex-end;
  }
}
