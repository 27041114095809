.tooltip {
  &.in {
    filter: alpha(opacity=90);
    opacity: 0.9;
  }
  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-top-color: transparent;
    border-style: solid;
  }
  &.top {
    padding: 5px 0;
    margin-top: -3px;
    .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 5px 5px 0;
      border-top-color: #000;
    }
  }
}

.table-tip {
  top: 1px !important;
  .tooltip-inner {
    line-height: 1;
    padding: 3px 6px 5px 6px;
  }
  .arrow {
    top: -1px !important;
  }
}
