/* How It Works */
.hWork-wrap{
	margin:0 auto;
	max-width:1920px;
}
.hWork-wrap .h2-01{
	margin:0 0 0.8333em 0;
}
.hw-inner{
	background:#0E477A;
    padding:7.5em 0 0 0;
    overflow: hidden;
}
.hw-inner .d-flex{
	border-bottom:1px solid #04F0FC;
	margin-bottom:3.75em;
}
.hw-block{
	width:33.333%;
	text-align:center;
	color:#fff;
	padding:0 0 5em 0;
}
.hw-block i{  	
	font-size: rem(110px);
	color:#04F0FC;
	height:1.04em;
	margin-bottom:0.3333em;
}
.hw-block p,.p-6{  	
  	font-family: 'Opensans';
	font-size: 30px;
	color:#fff;
	line-height:1.375em;
	margin:0;
}
.hw-block h3{  	
	font-family: 'Play'; 	
	font-weight: bold;
	font-size: rem(35px);
	color:#fff;
	font-weight:bold;
	line-height:1.1666em;
}
.p-6{
	text-align:center;
	margin-bottom:0.75em;
}
.signup-btn01{
	text-align:center;
}
.signup-btn01 a{
	margin:0 auto;
	background:#04F0FC;
	font-family: 'Play'; 	
	font-weight: bold;
	font-size: rem(30px);
	text-align:center;
	color:#0E477A;
	font-weight:bold;
	border-radius:0.2380em;
}
.hw-tab{
	display:block;
}
.hw-tab li{
	float:left;
}
.hw-tab li a{
	display:block;
	background:#04F0FC;
	font-family: 'Play';
	font-weight: bold;
	font-size:1.5em;
	text-transform:uppercase;
	color:#fff;
	text-align:center;
	border-radius:0 !important;
	border-top-left-radius:0.4166em !important;
	border-top-right-radius:0.4166em !important;
	padding: 20px 80px;
}
@include media-breakpoint-down(sm){
	.hw-inner .d-flex{
		display:block !important;
	}
	.hw-inner{
		padding: rem(60px) 0 0;
	}
	.hw-block{
		width:100%;
	}
	.hw-tab li a{
		width:auto;
		padding: 10px 18px;
		font-size: rem(18px);
	}
	.hw-block i{  	
		font-size: rem(60px);
	}
}
.hw-tab .nav-link.active, .hw-tab .show > .nav-link {
    color: #fff;
    background-color: #0E477A;
}