/* 
    Background Color
*/
@mixin background-variant-opacity($parent, $value, $opacity) {
  #{$parent} {
    background-color: rgba($value, ($opacity/ 10));
  }
}
$opacity: (9, 8, 7, 6, 5, 4, 3, 2, 1);

@each $color, $value in $theme-colors {
  @each $opac in $opacity {
    @include background-variant-opacity(".bg-#{$color}-#{$opac}", $value, $opac);
  }
}
