/* Header */
.ms-hdr {
  position: relative;
  padding-top: 91px;
  @media all and (max-width: 640px) {
    padding-top: 35px;
  }
  .navbar {
    background: rgba(255, 255, 255, 1);
    padding: 0;
    @include media-breakpoint-down(md) {
      background: #fff;
    }

    .dropdown-menu {
      margin-top: 0px;
      border: 0px solid transparent;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
      padding: 0px;
      &.dropdown-menu-right {
        right: -10px;
      }
      .dropdown-item {
        padding: 10px 20px;
        border-bottom: 1px solid $light;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -11px;
        right: 13px;
        width: 0;
        height: 0;
        border-color: transparent transparent $gray transparent;
        border-style: solid;
        border-width: 0 12px 11px 12px;
        z-index: -2;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: -10px;
        right: 13px;
        width: 0;
        height: 0;
        border-color: transparent transparent #fff transparent;
        border-style: solid;
        border-width: 0 12px 11px 12px;
        z-index: -1;
      }
    }
    .hdr-bottom {
      h1 {
        .tagline {
          @media all and (max-width: 1040px) and (min-width: 1024px) {
            font-size: 15px;
          }
          @media all and (max-width: 1024px) {
            display: none;
          }
        }
      }
      padding-right: 24px;
      @media all and (max-width: 992px) {
        padding-right: 16px;
      }
      @media all and (max-width: 640px) {
        padding-right: 8px;
      }
    }
  }
  .navbar-brand {
    display: inline-flex;
    align-self: stretch;
    font-size: 1em;
    padding: 0;
    vertical-align: middle;
    margin-bottom: 0px;
    a {
      font-family: "Play";
      font-weight: bold;
      font-size: 15px;
      text-transform: uppercase;
      vertical-align: middle;
      padding: 10px 0;
      display: inline-flex;
      align-self: stretch;
      align-items: center;
      color: #0e477a;
      img {
        width: 30px;
        height: 30px;
        line-height: 1em;
        margin-right: 10px;
      }
      &.active {
        background: $info;
      }
      @media all and (max-width: 640px) {
        padding: 5px 0;
        img {
          width: 25px;
          height: 25px;
        }
        b {
          font-size: 12px;
        }
      }
    }
    & > span {
      display: inline-flex;
      align-self: stretch;
      align-items: center;
    }
    @media all and (max-width: 410px) {
      margin-left: 10px !important;
    }
  }
  .navbar-toggler {
    padding: 0.1875em 0.5em;
    margin-right: 0.625em;
  }
}

.hdr-top {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid rgba(59, 89, 153, 0.15);
  @media all and (max-width: 640px) {
    display: none;
  }
  ul {
    li {
      a {
        img {
          max-width: 2.5em;
        }
      }
    }
  }
}

.main-menu {
  text-align: right;
  margin-bottom: 0px;
  align-items: center;
  & > li {
    padding: 0 0 0 20px;
    a {
      font-family: "Play";
      font-weight: bold;
      color: $secondary;
      font-size: 13px;
      text-transform: uppercase;
      display: inline-flex;
      align-items: center;
      i.fa-chevron-down {
        font-size: 16px;
      }
    }
    &.social-icon {
      a {
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &.fb {
          background: #0e477a;
        }
        &.yt {
          background: #c4302b;
        }
        i {
          font-size: 20px;
          color: white;
        }
      }
    }
  }
  & > li.all-service {
    // padding-left:0;
    // margin-right: rem(100px);
    & > a {
      font-family: "Play-Bold";
      color: #343d3d;
      & > b {
        display: block;
        float: left;
        font-weight: normal;
      }
      & > i {
        float: left;
        margin: 0 0 0 5px;
      }
      &:hover {
        color: #3b5999;
      }
    }
  }
}
.main-menu {
  .dropdown-menu {
    background: #0e477a;
    min-width: rem(200px);
    border-radius: 0 0 rem(10px) rem(10px);
    top: rem(43px);
    left: 50%;
    transform: translateX(-50%);
    border: none;
    padding: 0;
    ul {
      li {
        border-bottom: 1px solid #98bfcd;
        a {
          display: block;
          font-family: "Play-Bold";
          color: #fff;
          text-align: center;
          line-height: 3.05em;
          &:hover,
          &.active {
            background: #04f0fc;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &:last-child {
          border-bottom: none;
          a {
            &:hover,
            &.active {
              border-bottom-left-radius: 0.625em;
              border-bottom-right-radius: 0.625em;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      transform: translateX(0);
      top: unset;
      left: unset;
      border-radius: 0px;
      background: transparent;

      ul {
        li {
          a {
            color: #061717;
            text-align: right;
            line-height: 1.3em;
            font-family: "Opensans-Regular";
            font-weight: bold;
            font-size: 18px;
            text-transform: uppercase;
            padding-right: 40px !important;
          }
        }
      }
    }
  }
}

.user-nav {
  align-items: stretch;
  align-self: stretch;
  .nav-item {
    margin-left: 20px;
    line-height: 1;
    align-items: stretch;
    display: flex;
    &:first-child {
      margin-left: 0px;
    }
    a {
      position: relative;
      display: flex;
      align-items: center;

      i[class*="icon-"] {
        font-size: 20px;
      }
      span {
        &.count {
          font-size: 9px;
          line-height: 9px;
          padding: 2px 4px;
          background-color: $danger;
          color: white;
          position: absolute;
          top: 10px;
          right: -9px;
          border-radius: 2px;
        }
      }
      &.user-thumb-sm {
        margin: auto;
      }
      &.w-200px {
        @media all and (max-width: 640px) {
          width: 100px;
          font-size: 13px;
          i {
            display: none;
          }
        }
        @media all and (max-width: 410px) {
          width: 90px;
          font-size: 12px;
        }
      }
    }
  }
}
.navbar-collapse.show,
.navbar-collapse.collapsing {
  background: white;
  .main-menu {
    li {
      font-size: 18px;
      border-bottom: 1px solid #f2f2f2;
      padding: 0px;
      a {
        padding: 6px 13px;
        display: block;
        &:hover {
          background: #f2f2f2;
        }
      }
    }
  }
}

.notification-dropdown-list{
  max-height: 300px;
  overflow-y: auto;
}

.mobile-menu-trigger {
  display: none;
  @media all and (max-width: 640px) {
    display: flex;
    align-items: center;
    a {
      padding: 0 10px;
      i {
        font-size: 30px;
      }
    }
  }
}

.mobile-menu {
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s 0s, transform 0.3s 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999;
  &.show {
    transform: scale(1);
    opacity: 1;
    transition: transform 0.1s 0s, opacity 0.3s 0.1s;
  }
  a.close-mobile-menu {
    color: white;
    position: absolute;
    right: 30px;
    top: 50px;
    i {
      font-size: 30px;
    }
  }
  ul {
    width: 100%;
    li {
      a {
        color: white;
        padding: 10px 0;
        display: block;
        font-weight: bold;
      }
    }
  }
}
