//
//  Checkbox styling
//
.custom-control {
  &.custom-checkbox {
    .custom-control-label {
      line-height: 20px;
    }
  }
}
