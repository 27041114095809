/* 
// Icons
*/

.icon-md{
 font-size: 20px;
}

.icon-thumb{
    font-size: 80px;
}