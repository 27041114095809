/* 
// Donor status section style
*/

.donor-level{
    display: flex;
    align-items: center;
    img{
        width: 58px;
    }
    h5{
        line-height: 1;
        font-family: 'Play-Bold';
    }
}