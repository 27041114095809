.rat-favShow{
	font-size: rem(18px);
	color:#C2C6C9;
}
.rat-favShow a i{
	color:#D75A4A;	
}
.rat-favShow a.selected i{
	color:#F7D801;
	cursor:default;	
}
.rat-favShow a.selected:last-child i{
	color:#D75A4A;	
}