.k-grid {
  overflow-x: auto;
}
table {
  &.table {
    thead {
      &.bddot-striped {
        tr {
          th {
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 0px solid transparent;
            border-top: 0px solid transparent;
            font-weight: normal;
            text-align: center;
            &:nth-child(2n-1) {
              background: $light;
            }
            &:nth-child(2n) {
              background: #c6c6c6;
            }
          }
        }
      }
    }
    tbody {
      &.align-middle {
        tr {
          td {
            vertical-align: middle;
          }
        }
      }
    }
  }
}
.table-sm {
  font-size: 12px;
}
.table-borderless {
  td,
  th {
    border: 0px;
  }
  thead {
    th {
      border: 0px;
    }
  }
}

.table-bddot {
  margin-bottom: 0px;
  thead {
    background: $light;
    tr {
      td,
      th {
        padding: 10px 4.8px;
      }
    }
  }
  &.table-striped {
    tbody {
      tr {
        background: $light;
        &:nth-of-type(2n + 1) {
          background: white !important;
        }
      }
    }
  }
}

.fixed-header-table {
  height: 150px;
  table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    overflow-x: auto;
    thead {
      /* head takes the height it requires, 
      and it's not scaled when table is resized */
      flex: 0 0 auto;
      width: 100%;
      min-width: 555px;
      tr {
        width: 100%;
        display: table;
        table-layout: fixed;
      }
    }
    tbody {
      /* body takes all the remaining available space */
      flex: 1 1 auto;
      display: block;
      min-width: 555px;
      overflow-y: scroll;
      tr {
        width: 100%;
        display: table;
        table-layout: fixed;
        td {
          min-width: 78px;
        }
      }
    }
  }
  &.on-dashboard {
    height: 300px;
  }
}
// table.fixed-header {
//   table-layout: fixed;
//   border-collapse: collapse;
//   thead {
//     th {
//       width: 85px;
//     }
//   }
//   tbody {
//     display: block;
//     overflow-y: auto;
//     height: 120px;
//     width: 100%;
//     tr {
//       width: 100%;
//       display: block;
//       td {
//         width: 85px;
//       }
//     }
//   }
// }

.doc-list-appt-table {
  tbody {
    tr {
      td {
        height: 30px;
        font-size: 13px;
      }
    }
  }
}
