// Scrollbar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba($light, 0.2);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: #f5f5f5;
  cursor: pointer;
  pointer-events: all;
  &:hover {
    cursor: pointer;
    pointer-events: all;
  }
}

::-webkit-scrollbar-thumb {
  background-color: rgba($blue, 0.6);
  cursor: pointer;
  pointer-events: all;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background-color: rgba($blue, 1);
    pointer-events: all;
  }
}
