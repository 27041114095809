/* Login Registration */

.login-box{
	text-align:left;
	padding-left:1.5625em;
	padding-right:1.5625em;
}

.feture-nav.login-nav{
	text-align:left;
	border-bottom:1px solid #F4F5F6;
	justify-content: start;	
}
.login-nav li{
	margin-right: rem(15px);
	a{
	   background:#F4F5F6;
	   font-family: 'Opensans-Bold';
	   color:#343D3D;
	   text-transform:none;
	   border-radius: 5px;
	   padding: rem(10px) rem(78px);
   }
}
.login-tab-content{
	padding:2.5em 0;
}


.reg-fields{
	background:#fff;
	border-radius: rem(3px);
	padding: rem(35px) rem(25px) rem(25px) rem(25px);
	margin-bottom:1em;
}
.sBtn-list1 li{
	margin-right:10px;
}
.sBtn-list1 li a{
	display: flex;
	justify-content: center;
	align-items: center;
	background:#1168B5;
	border-radius:4px;
	padding: rem(15px) 0;
	width: rem(180px);
	color:#fff;
}
.sBtn-list1 li:nth-child(2) a{
	background:#04F0FC;
}
.sBtn-list1 li:nth-child(3) a{
	background:#FC0404;
}
.sBtn-list1 li a i{
	font-size:3.125em;
}


.resend-btn1{
	margin:0 auto;
	max-width:350px;
	margin-bottom:1.25em;
}
.resend-btn1 a{
	background:#04F0FC;
	color:#0E477A;
}


/* Styling for form */
// .form-control {
//   font-size: 16px;
// }

// .btn {
//   width: 100%;
//   padding: 12px;
//   font-size: 16px;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
// }

/* Google Button */
.btn-google {
  background-color: #db4437;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-google i {
  margin-right: 8px;
}

.btn-google:hover {
  background-color: #c23321;
}

/* Facebook Button */
.btn-facebook {
  background-color: #4267B2;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-facebook i {
  margin-right: 8px;
}

.btn-facebook:hover {
  background-color: #365899;
}

/* OR Divider */
.divider-text {
  font-size: 14px;
  color: #888;
  position: relative;
}

.divider-text:before,
.divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background: #ddd;
}

.divider-text:before {
  left: 0;
}

.divider-text:after {
  right: 0;
}

