.dateChanger {
  display: flex;
  align-items: center;
  a {
    &.prev,
    &.next {
      font-size: 12px;
      padding: 2px 4px;
      height: 22px;
      border: 1px solid $primary;
    }
  }
  .date {
    min-width: 180px;
    display: inline-block;
    text-align: center;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    height: 22px;
    line-height: 22px;
    position: relative;
    .vdp-datepicker {
      position: absolute;
      top: 0;
      width: 100%;
      .input-group {
        input.form-control {
          padding: 0px 10px;
          height: 20px;
          border: 0px;
          border-radius: 0px;
          background: $warning;
          text-align: center;
          cursor: pointer;
          font-size: 11px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .vdp-datepicker__calendar {
        width: 180px;
        header {
          .day__month_btn {
            font-size: 12px;
            font-weight: bold;
          }
        }
        .cell {
          height: 20px;
          line-height: 20px;
        }
      }
    }
    &.bg-blue {
      .vdp-datepicker {
        .input-group {
          input.form-control {
            background: $blue;
            color: white;
          }
        }
      }
    }
  }
}

.calander {
  position: relative;
  .vdp-datepicker {
    position: absolute;
    top: 0;
    width: 100%;
    .input-group {
      input.form-control {
        padding: 0px 10px;
        height: 20px;
        border: 0px;
        border-radius: 0px;
        background: transparent;
        text-align: center;
        cursor: pointer;
      }
    }
    .vdp-datepicker__calendar {
      width: 180px;

      header {
        .day__month_btn {
          font-size: 12px;
          font-weight: bold;
        }
      }
      .cell {
        height: 20px;
        line-height: 20px;
        font-size: 10px;
      }
    }
  }
}
