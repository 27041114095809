/* 
// Circles
*/

.circle-80px {
	width: 80px;
	height: 80px;
	border-radius: 80px;
}

.circle-75px {
	width: 75px;
	height: 75px;
	border-radius: 75px;
}
.circle-62px {
	width: 62px;
	height: 62px;
	border-radius: 62px;
}

.circle-48px {
	width: 48px;
	height: 48px;
	border-radius: 48px;
}

.circle-35px {
	width: 35px;
	height: 35px;
	border-radius: 35px;
}
.circle-30px {
	width: 30px;
	height: 30px;
	border-radius: 30px;
}

.circle-400px {
	width: 400px;
	height: 400px;
	border-radius: 100%;
}

.circle-118px {
	width: 118px;
	height: 118px;
	border-radius: 100%;
}

.bullet {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
