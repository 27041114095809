/* Colors */
$primary: #0e477a;
$secondary: #444444;
$light-secondary: #5b5b5b;
$success: #33ce06;
$info: #04f0fc;
$pacific: #03b4bc;
$morningGlory: #89c8dd;
$cyprus: #013a3d;
$blueLagoon: #02777c;
$warning: #fcd304;
$light-yellow: #fff5cc;
$danger: #d75a4a;
$monalisa: #ffa197;
$light: #f4f5f6;
// $dark:          $gray-800 !default;
$blue: #1168b5;
$gray: #c2c6c9;
$tartiary: #959595;
$elf-green: #06797e;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "green": $success,
  "info": $info,
  "pacific": $pacific,
  "morningGlory": $morningGlory,
  "blueLagoon": $blueLagoon,
  "warning": #fcd304,
  "danger": #d75a4a,
  "monalisa": $monalisa,
  "light": #f4f5f6,
  // "$dark":          $gray-800 !default,
    "blue": #1168b5,
  "gray": $gray,
  "tartiary": $tartiary,
  "cyprus": $cyprus,
  "elf-green": $elf-green
);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.8125rem; // Assumes the browser default, typically `16px`
$font-size-lg: 1.125rem; // 18px
$font-size-sm: 0.75rem; //12px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 3.076; //40px
$h2-font-size: $font-size-base * 2.462; //32px
$h3-font-size: $font-size-base * 2.154; //28px
$h4-font-size: 16px;
$h5-font-size: 14px;
$h6-font-size: 13px;

$headings-font-weight: 700;

/* $headings-margin-bottom:      ($spacer / 2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              ($font-size-base * 1.25) !default;
$lead-font-weight:            300 !default;

$small-font-size:             80% !default;

$text-muted:                  $gray-600 !default;

$blockquote-small-color:      $gray-600 !default;
$blockquote-font-size:        ($font-size-base * 1.25) !default;

$hr-border-color:             rgba($black, .1) !default;
$hr-border-width:             $border-width !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;

$hr-margin-y:                 $spacer !default; */

// Components
//
// Define common padding and border radius sizes and more.

// $line-height-lg:              1.5 !default;
// $line-height-sm:              1.5 !default;

$border-width: 2px;
// $border-color:                $gray-300 !default;

$border-radius: 4px;
// $border-radius-lg:            .3rem !default;
// $border-radius-sm:            .2rem !default;

// $component-active-color:      $white !default;
// $component-active-bg:         theme-color("primary") !default;

// $caret-width:                 .3em !default;

// $transition-base:             all .2s ease-in-out !default;
// $transition-fade:             opacity .15s linear !default;
// $transition-collapse:         height .35s ease !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.45rem;
$input-btn-padding-x: 1.25rem;
$input-btn-line-height: 1.23;

// $input-btn-focus-width: 0;
// $input-btn-focus-color: transparent;
// $input-btn-focus-box-shadow: 0 0 0 0 transparent;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-line-height-sm: 1;

// $input-btn-padding-y-lg:      .5rem !default;
// $input-btn-padding-x-lg:      1rem !default;
// $input-btn-line-height-lg:    $line-height-lg !default;

// $input-btn-border-width:      $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// $btn-padding-y:               10px !default;
// $btn-padding-x:               20px !default;
// $btn-line-height:             $input-btn-line-height !default;

// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
// $btn-padding-x-sm:            $input-btn-padding-x-sm !default;
// $btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg: 10px;
$btn-padding-x-lg: 30px;
// $btn-line-height-lg:          $input-btn-line-height-lg !default;

// $btn-border-width:            $input-btn-border-width !default;

// $btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow: 0 0px 0 0 transparent;
// $btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow: 0 0px 0 0 transparent;
// $btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow: 0 0px 0 0 transparent;

// $btn-link-disabled-color:     $gray-600 !default;

// $btn-block-spacing-y:         .5rem !default;

// Forms

// $input-padding-y:                       $input-btn-padding-y !default;
// $input-padding-x:                       $input-btn-padding-x !default;
// $input-line-height:                     $input-btn-line-height !default;

// $input-padding-y-sm:                    $input-btn-padding-y-sm !default;
// $input-padding-x-sm:                    $input-btn-padding-x-sm !default;
// $input-line-height-sm:                  $input-btn-line-height-sm !default;

$input-padding-y-lg: 0.815rem;
$input-padding-x-lg: 1.5rem;
// $input-line-height-lg:                  $input-btn-line-height-lg !default;

// $input-bg:                              $white !default;
// $input-disabled-bg:                     $gray-200 !default;

// $input-color:                           $gray-700 !default;
// $input-border-color:                    $gray-400 !default;
// $input-border-width:                    $input-btn-border-width !default;
// $input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;

// $input-border-radius:                   $border-radius !default;
// $input-border-radius-lg:                $border-radius-lg !default;
// $input-border-radius-sm:                $border-radius-sm !default;

// $input-focus-bg:                        $input-bg !default;
// $input-focus-border-color:              lighten($component-active-bg, 25%) !default;
// $input-focus-color:                     $input-color !default;
// $input-focus-width:                     $input-btn-focus-width !default;
// $input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

// $input-placeholder-color:               $gray-600 !default;

// $input-height-border:                   $input-border-width * 2 !default;

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    ),
    6: (
      $spacer * 1.25
    ),
    7: (
      $spacer * 2.5
    ),
    10: 10px,
    15: 15px,
    20: 20px,
    30: 30px,
    100: 100px
  ),
  $spacers
);

@function negativify-map($map) {
  $result: ();
  @each $key, $value in $map {
    @if $key != 0 {
      $result: map-merge($result, ("n" + $key: (-$value)));
    }
  }
  @return $result;
}

$negative-spacers: negativify-map($spacers);

$utilities: () !default;

$utilities: map-merge(
  (
    // Negative margin utilities
      "negative-margin":
      (
        responsive: true,
        property: margin,
        class: m,
        values: $negative-spacers
      ),
    "negative-margin-x": (
      responsive: true,
      property: margin-right margin-left,
      class: mx,
      values: $negative-spacers
    ),
    "negative-margin-y": (
      responsive: true,
      property: margin-top margin-bottom,
      class: my,
      values: $negative-spacers
    ),
    "negative-margin-top": (
      responsive: true,
      property: margin-top,
      class: mt,
      values: $negative-spacers
    ),
    "negative-margin-right": (
      responsive: true,
      property: margin-right,
      class: mr,
      values: $negative-spacers
    ),
    "negative-margin-bottom": (
      responsive: true,
      property: margin-bottom,
      class: mb,
      values: $negative-spacers
    ),
    "negative-margin-left": (
      responsive: true,
      property: margin-left,
      class: ml,
      values: $negative-spacers
    )
  ),
  $utilities
);
