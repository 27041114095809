/* 
// Timeline
*/

.timeline{
    padding-left: 0px;
    li{
        padding-left: 45px;
        position: relative;
        margin: 0px;
        padding-bottom: 40px;
        border-left: 1px solid #444;
        &:before{
            content: url('./assets/images/verified-green.png');
            position: absolute;
            left: -18px;
        }
        &:last-child{
            border-left: 0px;
        }
        p{
            line-height: 28px;
            strong{
                font-family: 'Opensans-Bold', sans-serif;
            }
        }
    }
}