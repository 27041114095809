.alert-dock {
	position: fixed;
	bottom: 0px;
	width: calc(100% - 30px);
	z-index: z("alert");
	margin-bottom: 15px;
	margin-left: rem(15px);
}

.push-alert {
	min-width: 360px;
	width: 60%;
	position: fixed;
	top: 0px;
	left: 0px;
	display: flex;
	z-index: 1090;
	padding: 7px 20px;
	margin-bottom: 0px;
	background: $blue;
	border-color: $blue;
	color: white;
	justify-content: center;
	button.close {
		top: 50%;
		transform: translateY(-50%);
		&:hover {
			color: white;
		}
	}
}
