/* Search Result */
.totalResult{
	margin: rem(40px) 0 rem(30px) 0;
}
.totalResult b{
  	font-family: 'Opensans-Light';
	font-weight:normal;
	color:#95989A;
}

/* .srch-result{
	padding: rem(40px) 0 rem(50px) 0;
} */
.subpage-col2 .rat-favShow{
	text-align:left;
	margin:-0.625em 0 2em 0;
}
.check01{
	display:block;
	width:1.5em;
	height:1.5em;
	text-align:center;
	border:1px solid #95989A;
	border-radius:0.375em;
	cursor:pointer;
	margin-right:0.625em;
}
.check01 i{
	display:none;
	line-height:1.375em;   
 }
.check01.selected i{
	display:block; 
 }
.label01{
	display:table-cell;
	line-height:1.375em;
	margin:0;
}
.label02{
	display:block;
	line-height:1.375em;
}
.pg-bar01{
	background:#C2C6C9;
	height:0.625em;
	border-radius:0.625em;
	margin:2.875em 0 2.25em 0;
}
.pg-bar01 p{
	position:relative;
	margin:0 auto;
	background:#04F0FC;
	height:0.625em;
}
.pg-bar01 p b{
	display:block;
	background:#0E477A;
	position:absolute;
	top:-0.5em;
	left:-0.5em;
	width:1.5625em;
	height:1.5625em;
	border-radius:1.5625em;

}
.pg-bar01 p b:last-child{
	left:inherit;
	right:-0.5em;
}
.av-checkbox .rat-favShow{
	margin-top:-0.25em;
}
.av-checkbox .rat-favShow a.selected:last-child i{
	color:#F7D801;	
}
.map-box1{
	width:100%;
	padding: rem(15px) 0 rem(30px) 0;
}
.hw-tab{
	display:block;
}
.hw-tab li{
	float:left;
}
.fltr-buttons a{
	display:block;
	background:#04F0FC;
  	font-family: 'Play-Regular';
	font-size:1.3125em;
	color:#fff;
	text-align:center;
	font-weight:bold;
	border-radius:0.4166em;
	height:3.3333333333333335em;
	display: flex;
	justify-content: center;
	align-items: center;
}
.fltr-buttons a:first-child{
	margin-right:2.6666em;
}
.checkbox {
	padding-left: 0; 
}
.checkbox label{
    display: inline-block;
    position: relative;
    padding-left: 0.625em; 
}
.checkbox label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 1.5em;
	height: 1.5em;
	left: 0;
	margin-left: -20px;
	border: 1px solid #95989A;
	border-radius: 0.375em;
	background-color: #fff;
	-webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
	-o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
	transition: border 0.15s ease-in-out, color 0.15s ease-in-out; 
}
.checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 1.5em;
	height: 1.5em;
	left: 0;
	top: 0;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 1em;
	color: #555555;
}
.checkbox input[type="checkbox"]{
    opacity: 0; 
}
.checkbox input[type="checkbox"]:focus + label::before {
	outline: 0;
/*	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px; */
}
.checkbox input[type="checkbox"]:checked + label::after {
	font-family: 'FontAwesome';
	content: "\f00c";
}
.checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before {
	background-color: #eeeeee;
	cursor: not-allowed; 
}
.checkbox.checkbox-circle label::before {
    border-radius: 50%; 
}
.checkbox.checkbox-inline {
    margin-top: 0; 
}