/* Admin */
.admin-menu {
  padding: 20px 0;
  li {
    margin-bottom: 0.625em;
    a {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: $secondary;
      padding: 5px 20px;
      border-radius: 5px;

      @include media-breakpoint-down(md) {
        padding: rem(10px) 0 rem(10px) rem(10px);
      }
      i {
        max-width: 1.6667em;
        margin-right: 10px;
      }
      b {
        font-weight: normal;
        line-height: 1.111em;
        max-width: 350px;
        word-wrap: break-word;
        flex: 1 1 auto !important;
      }
      &:hover,
      &.active {
        background: $warning;
      }
      &[aria-expanded="false"]::after {
        content: "\f0da";
        display: inline;
        font-family: Font Awesome\5 Free;
        font-weight: 900;
        text-align: right;
        padding-right: 15px;
        transition: all 0.3s;
      }
      &[aria-expanded="true"]::after {
        content: "\f0d7";
        font-family: Font Awesome\5 Free;
        font-weight: 900;
        display: inline;
        text-align: right;
        padding-right: 15px;
        transition: all 0.3s;
      }
    }
  }
}

// Monthly Appointments Chart
// .chart-months-outer{
// 	max-height: 467px;
// 	overflow-y: auto;
// }
.chart-months {
  background: #fff;

  li {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary;
      padding: 5px 0;
      margin-bottom: 10px;
      font-size: 15px;
      border-radius: 5px;
      &:hover,
      &.active {
        background: $warning;
      }
    }
  }
}
.mApp-chart {
  background: #fff;
  min-height: 29.0625em;
  & > div > div {
    & > div {
      width: calc(100% - 30px) !important;
      left: 15px !important;
    }
    svg {
      width: 100%;
    }
  }
}

// Upload profile image
.upload-pro-image {
  background: #fff;
  border-radius: 4px;
  span {
    display: inline-block;
    max-width: 130px;
    img {
      width: 100%;
      border-radius: 4px;
    }
    p {
      font-size: 1.25em;
      color: #bfc1c2;
      margin: 0;
    }
  }
}

.add1 {
  text-align: left;
  color: #95989a;
  margin-top: 2em;
}
.add1 h4 {
  font-family: "Opensans-Bold";
  line-height: 1em;
}
.add1 .d-flex {
  margin-bottom: 0.3125em;
}
.add1 {
  i {
    display: block;
    display: flex;
    align-items: center;
    font-size: rem(22px);
    color: #484848;
    margin-left: 0.7142em;
    &.fa-times {
      color: red;
    }
  }
  p {
    font-size: 1.25em;
    margin-bottom: 0;
  }
}
.add2 {
  color: #04f0fc;
}
.sDate1 {
  max-width: 256px;
  color: #bfc1c2;
  background: #fdfdfe;
  border: 1px solid #f3f4f4;
  border-radius: 5px;
  padding: 0 1em;
  margin-bottom: 1em;
  margin-right: 0.625em;
  i {
    font-size: 1.25em;
    color: #95989a;
    display: flex;
    align-items: center;
  }
}
.eDate1 {
  margin-right: 0;
}

.time-slot1 {
  margin-left: -1.625em;
  margin-right: -1.625em;
}
.time-slot1 li {
  background: #fff;
  float: left;
  text-align: left;
  color: #858b8b;
  width: 9.875em;
  height: 6.0625em;
  border-radius: 0.3125em;
  border: 1px solid #04f0fc;
  padding: 10px;
  margin: 0 0 1.25em 1.625em;

  @include media-breakpoint-down(sm) {
    width: calc(100% - 50px);
  }
}
.time-slot1 li.active {
  background: #04f0fc;
  color: #343d3d;
}
.time-slot1 li .d-flex {
  margin-bottom: 0.625em;
}
.time-slot1 li h4 {
  font-size: 1.125em;
  line-height: 1.125em;
}
.time-slot1 li i {
  font-size: 1.25em;
  color: #fc0404;
}
.time-slot1 li p {
  font-size: 0.875em;
  margin: 0;
}
.time-slot1 li p em {
  font-style: normal;
}
.addEdit-box1 {
  background: #f4f5f6;
  border-radius: 0.625em;
  min-height: 13.625em;
  padding: 4.875em 1em 0 1em;
}
.addEdit-box1 b {
  display: block;
  font-size: 1.125em;
  border-bottom: 1px solid #c2c6c9;
  padding-bottom: 3px;
  margin-bottom: 2px;
}
.addEdit-box1 p {
  font-size: 1.125em;
  color: #04f0fc;
  margin: 0;
}
.addEdit-box1 p em {
  color: #fc0404;
  font-style: normal;
}
.span07 {
  display: block;
  font-size: 1.5em;
  color: #343d3d;
  text-align: left;
  padding-top: 3.66666em;
}

.appTable-h {
  background: #f4f5f6;
  min-height: 2.625em;
}
.appTable-h h4 {
  word-break: break-all;
  text-align: center;
  font-size: 1.25em;
  padding-top: 0.25em;
}
.bgGr01 {
  background: #c2c6c9;
}
.appTable-b {
  background: #fff;
  border-bottom: 1px solid #f4f5f6;
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.title-col {
  word-break: break-all;
  padding-top: 1em;
  padding-bottom: 1em;
  span {
    color: #343d3d;
  }
  p {
    color: #95989a;
    margin: 0;
  }
}
.time-col {
  text-align: center;
  background: #f4f5f6;
  color: #95989a;
  padding-top: 1.6666em;
}
.action-col {
  text-align: center;
  padding-top: 1.6666em;
  a i {
    font-size: rem(25px);
    &.fa-check-circle {
      color: #04f0fc;
    }
    &.fa-minus-circle {
      color: #fc0404;
    }
  }
}
.action-col a i .date-col {
  text-align: center;
  font-size: 1.125em;
  color: #95989a;
  padding-top: 1.6666em;
}

// Dashboard
.rec-block,
.fav-block {
  text-align: center;
  background: #fcfcfd;
  border-radius: 0.625em;
  padding: 1em 0;
}

.edit-pr1 {
  background: #f4f5f6;
  border-radius: 0.3125em;
  padding: 2em 2.5em;
  margin-bottom: 2.5em;
}

.edit-pr1 .add1 h4 {
  color: #343d3d;
}
.edit-pr1 .add2 h4 {
  color: #04f0fc;
}
.edit-pr1 .add1 p.d-flex span {
  display: block;
  max-width: 50%;
  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}
