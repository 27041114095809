.total-stats {
	.stat-icon {
		i {
			font-size: 50px;
		}
	}
	.stat-value {
		h3 {
			font-size: 38px;
			font-family: $openSansBold;
			line-height: 1em;
			color: $cyprus;
		}
		p {
			font-size: 16px;
			font-weight: 600;
		}
	}
}
.numeric-stats {
	display: flex;
	min-height: 61px;

	.value {
		display: flex;
		align-items: center;
		padding: 0 16px;
		border-radius: $border-radius 0 0 $border-radius;
		h1 {
			line-height: 1em;
		}
		h3 {
			font-size: 18px;
			line-height: 20px;
			font-weight: 400;
		}
		h4 {
			line-height: 1.3em;
		}
	}
	.property {
		display: flex;
		align-items: center;
		padding-left: 24px;
		padding-right: 16px;
		border-radius: 0 $border-radius $border-radius 0;
		h3 {
			font-size: 18px;
			line-height: 20px;
			font-weight: 400;
		}
		h4 {
			line-height: 1.3em;
		}
	}
	&-vertical {
		flex-direction: column;
		.value {
			border-radius: 0 0 $border-radius $border-radius;
			justify-content: center;
		}
		.property {
			border-radius: $border-radius $border-radius 0 0;
			justify-content: center;
		}
	}
}
