/* Hospital Listing-1 */
.req-box{
	justify-content: center;
	align-items: center;
	border:1px solid #95989A;
	padding:4.375em 1em;
	margin:2em 0 7.5em 0;
}
.req-box p{
	font-size:2.75em;
	line-height:1.111em;
	margin:0 1em 0 0;
}
.req-box .signup-btn01 {
    padding-bottom:0;
}
.req-box .signup-btn01 a{
	line-height:1.111em;
    padding:0.6em 1.5em;
}
.subpage-col2 .doc-block2 {
    max-width: 42.5em;
}
.inp06{
	border-radius:0;
	height:3.5em;
	border:1px solid #95989A;
	margin:1.5em 0 3em 0;
}
.sidebar .req-box{
	display:block !important;
	border:none;
	border-top:1px solid #95989A;
	text-align:center;
	padding:2em 0;
	margin:4em 0 7.5em 0;
}
.sidebar .req-box p{
	font-size:1.875em;
	color:#95989A;
	margin:0 0 1em 0;
}
.sidebar  .req-box .signup-btn01 a{
	display:block;
	background:#0E477A;
	max-width:none;
	font-size:1.5em;
	color:#fff;
	padding:0.6em 0;
}
/*Hospital Details – 1*/
.h4-05{
  	font-family: 'Opensans-Regular';
	font-weight:bold;
	padding:1em 1em 3em 1em;
}
.give-ratings .s-icons{
	font-size:2em;
}
.s-icons a{
	margin:0 0.25em;
}
.s-icons a i{
	color:#95989A;
}
.s-icons a:hover{
	opacity:0.7;
	transition:all 0.5s;
}
.svc-list01{
	margin:0 4em 1.5em 0.5em;
}
.svc-list01 li{
	margin:0 0 1em 0;
}
.svc-list01 li a{
	display:flex;
	font-size:1.5em;
	color:rgba(25,28,28,0.6);
	align-items: center;
}
.svc-list01 li a i{
	margin-right:5px;
}
.h3-04{
  	font-family: 'Opensans-Bold';
    font-size: 2.625em;
    color: #0E477A;
    line-height: 1em;
	margin:3em 0 1.5em 0;
}
/*Hospital Listing Form*/
.help-icon{
	display:block;
	text-align:center;
	padding:0.625em;
	margin-bottom:0.625em;
}
.help-icon i{
	font-size:8em;
}
.spec-list01{
	background:#fff;
	border-radius: 0.3125em;
	margin-bottom:1em;
}
.txtarea01{
	height:22.5em;
	padding-top:0.5em;
}
.req-btn button{
  	font-family: 'play-Bold';
	background: #04F0FC;
	font-size:2.625em;
	color:#0E477A;
	width:10.7142em;
	height:2.38095em;
	line-height:2.38095em;
}

@media (max-width: 991px) {
	body{
		font-size:12px;
	}
	.main-menu  .dropdown-menu{
		top: auto;
	}
	.ft-block p{  	
		padding:0 15px;
	}
	.feture-nav li a{
		padding:0 1.4em;
	}
	.admin-menu li a b{
		max-width:130px;
	}
	.edit-pr1 .add1 p.d-flex{
		display:block !important;
	}
}
@media (max-width:767px){
	body{
		font-size:11px;
	}
	.main-menu > li{
		display:block;
		padding-left:15px;
	}
	.main-menu > li:first-child{
		padding-left:15px;
	}
	.main-menu  .dropdown-menu{
		position: inherit;
		float:none;
		top:0;
		left:0;
		margin:0 15px 0 0;	
	}
	.ms-hdr .navbar-brand {
		margin-left:0.625em;
	}
	.sel-loc > a img,.sel-dept > a img,.sel-dr > a img,.srch-btn01 > a > b{
		display:none;
	}
	.feture-wrap .d-flex{
		display:block !important;
	}
	.ft-block{
		width:100%;
	}
	.srch-block h2{
		font-size:2.8em;
	}
	.h2-01{
		font-size:2.8em;
	}
	.feture-nav li{
		display:block;
		margin:0 auto;
		max-width:260px;
	}
	.feture-nav li a{
		padding:0 3.2083em;
	}
	.doc-block1 p img,.p-01 img{
		font-size:10px;
	}
	.doctor-row{
		display:block !important;
	}
	.doc-bl2Inner{
		text-align:center;	
	}
	.doc-block1,.doc-block2,.doc-block3{
		margin: 0 auto;
	}
	
	
	.mb-main-menu{
		display:block;
	}
	.mb-main-menu{
		background:#0E477A;
		margin:0 15px;
		height:400px;
		overflow-y: auto;
	}
	.mb-main-menu li{
		margin-bottom:1px;
	}
	.mb-main-menu li h3{
		font-family: 'Play-Bold';
		font-size:1.5em;
		font-weight:normal;
		color:#fff;
		padding:10px 20px 0 20px;
	}
	.mb-main-menu li a{
		display:block;
		font-family: 'Play-Bold';
		font-size:1.25em;
		color:#fff;
		text-transform: uppercase;
		line-height:3.05em;
		padding:0 0 0 50px;
	}
	.mb-main-menu li a:hover,.mb-main-menu li a.active{
		background:#04F0FC;
	}
	.srch-result{
		display:block !important;
	}
	.subpage-col1,.subpage-col2{
		max-width: 100%;
	}
	.sidebar {
    	margin:0 0 4em 0;
	}
	.doctor-details{
		display:block !important;
	}
	.time-box{
		display:block !important;
	}
	.cal-block{
		margin-bottom:1.25em;
	}
	.h3-03{
		font-size:2.125em;
	}
	.admin-menu li a b{
		max-width:350px;
	}
	.addEdit-box1{
		margin-bottom:1em;
	}
	.span07{
		padding-top:0;
	}
	.ul-date li em{
		width:2em;
		height:2em;
	}
	.ul-date li b{
		width:2em;
		height:2em;
	}
	.req-box{
		display:block !important;
		text-align:center;
	}
	.req-box p{
		margin:0 0 1em 0;
	}
	.req-box .signup-btn01 a{
    	padding:0.6em 0;
	}
	.spec-list01 li{
		display:block;
		width:98%;
	}
}
@media (min-width: 992px) {
	body{
		font-size:14px;
	}
	.container {
		max-width: 1462px;
	}
	.feture-nav li a{
		padding:0 2.2083em;
	}
	.main-menu > li{
		padding:0.625em 0 0 1.25em;
	}
}




