/* Book Appointment Modal */

.cm-modal .modal-dialog {
  max-width: 1024px;
  margin: 8.25em auto;
}
.cm-modal .modal-content {
  border: none;
}
.cm-modal .modal-header {
  border: none;
  color: #484848;
  align-items: center;
  padding: 1em 2.875em;
}
.cm-modal .modal-header .modal-title {
  font-family: "Play-Regular";
  font-size: 2em;
  line-height: 1.125em;
}
.cm-modal .modal-header .close {
  color: #484848;
  opacity: 1;
  padding: 0;
  margin: 0;
}
.cm-modal .modal-body {
  background: #fff;
  border: none;
  border-radius: 0.625em;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  padding: 2.875em;
}
.pg-bar02 {
  background: #f4f5f6;
  height: 0.625em;
  border-radius: 0.625em;
  margin: 0 0 rem(60px) 0;
}
.pg-bar02 ul li {
  position: relative;
  width: 33.3%;
  text-align: center;
}
.pg-bar02 ul li b {
  display: block;
  margin: 0 auto;
  background: #c2c6c9;
  position: absolute;
  top: -0.5em;
  left: 0;
  right: 0;
  width: 1.5625em;
  height: 1.5625em;
  border-radius: 1.5625em;
}
.pg-bar02 ul li {
  a {
    b {
      background: #c2c6c9;
    }
    span {
      display: none;
    }
    &.active {
      span {
        margin-top: 1em;
        color: #04f0fc;
        font-weight: bold;
        display: block;
      }
      b {
        background: #04f0fc;
      }
    }
  }
}
.h4-03 {
  font-size: 1.25em;
  line-height: 1.35em;
  margin-bottom: 1em;
}
.cal-block {
  max-width: 234px;
  background: #0e477a;
  border-radius: 0.625em;
  @include media-breakpoint-down(sm) {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
.selTime-block {
  max-width: 688px;
}
.pager-ul {
  border-radius: 0;
  border-bottom: 1px solid #fff;
  padding: 1.25em 0 1.25em 0;
  margin-bottom: 0.625em;
}
.pager-ul .page-link:focus {
  box-shadow: none;
}
.pager-ul .page-link {
  background: none;
  border: none;
  color: #04f0fc;
}
.pager-ul .page-link i {
  font-size: 1.2em;
}
.pager-ul .page-item span,
.pager-ul .page-item b {
  display: block;
  font-size: 1.125em;
  color: #fff;
  text-align: center;
  font-weight: normal;
  line-height: 1.3333em;
  padding-top: 0.625em;
}
.pager-ul .page-item b {
  padding-top: 0;
}
.span05 {
  display: block;
  font-size: 1.125em;
  color: #04f0fc;
  text-align: center;
  padding-bottom: 0.625em;
}

.selTime-block ul {
  margin: 0 0 0 0.5em;
}
.selTime-block ul li {
  float: left;
  font-size: 1.25em;
  color: #95989a;
  text-align: center;
  margin: 0 0 1em 0.5em;
}
.selTime-block ul li.selected {
  opacity: 0.6;
  border: 1px solid #f3f4f4;
}
.appt-buttons {
  padding: 1em 0 0 0;
}
.appt-buttons a,
.appt-buttons button {
  display: block;
  background: #484848;
  font-family: "Play-Regular";
  font-size: 1.125em;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  width: 11.1111em;
  height: 3.1111em;
  line-height: 3.1111em;
  border-radius: 0.4166em;
  margin: 0 0 0.625em 0;
}
.appt-buttons a:first-child {
  margin: 0 0.625em 0.625em 0;
}
.login-box .appt-buttons a,
.login-box .appt-buttons button,
.edit-pr1 .appt-buttons a,
.edit-pr1 .appt-buttons button {
  text-transform: capitalize;
}
.appt-buttons button {
  border: none;
  cursor: pointer;
}
.edit-pr1 .appt-buttons a,
.edit-pr1 .appt-buttons button {
  width: 100%;
  height: 2.7777em;
  line-height: 2.7777em;
}
.appointment-timings {
  max-height: 280px;
  margin-bottom: 15px;
  overflow-y: auto;
}
