/*Doctor Row*/
.doctor-row{
    .doctor-img{
		width: rem(190px);
		img{
			border-radius: rem(10px);
		}
	}
	.doctor-info{
		max-width: calc( 100% - 190px - 24px);
	}
}
.doc-block2{
	max-width:53.5em;
}
/* .doc-block3{
	max-width:20.375em;
} */
.doc-block1 span{
	display:block;
	margin-bottom:0.9375em;
}
.doc-block1 span img{
	width:100%;
	border-radius:1.25em;
}
.doc-block1 p,.p-01{
	text-align:center;
	margin:0;
}
.doc-block1 p img,.p-01 img{
	width:3em;
	height:3em;
	margin:0 0.3125em;
}
.doc-bl2Inner{	
	padding-left: rem(25px);
	width: calc(100% - 190px);
	@include media-breakpoint-down(sm){
		width: 100%;
		padding-left: 0px;
	}
}
.doc-bl2Inner h4,.h4-02{
  	font-family: 'Play-Bold';
	font-size: rem(25px);
	color:#484848;
	line-height: 1.1428em;
	margin: 0;	
}
.span01{
	display:block;
	font-size: rem(17px);
	margin-bottom: rem(20px);
}
.doc-adr{
	margin-bottom:0.3125em;
}
.doc-adr span{
	display: block;
	width: rem(30px);
	margin-right:0.625em;
}
.doc-adr p{
  	font-family: 'Opensans';
	// font-size:1.25em;
}
.btn-01{
	display:block;
	margin:30px 0 25px 0;
}
.btn-01 a,.btn-01 button{
	display:block;
	background:#0E477A;
  	font-family: 'Play-Bold';
	text-align:center;
	font-size:1.5em;
	color:#fff;
	text-transform:uppercase;
	height:2.9166em;
	border-radius:0.3125em;
	line-height:1.125em;
	display: flex;
	justify-content: center;
	align-items: center;
}
.btn-01 button{
	border:none;
	width:100%;
	cursor:pointer;
}

.dList-pagination{
	margin:0;
}
.dList-pagination .page-link{
	color:#95989A;
}
.dList-pagination .page-item.active .page-link {
    background-color: #04F0FC;
    border-color: #04F0FC;
}
.dList-pagination .page-link:focus {
    box-shadow: none;
}
.dList-pagination ul li:last-child .page-link,.dList-pagination ul li:first-child .page-link{
	color:#04F0FC;
}
.dList-pagination ul li.disabled .page-link{
	color:#95989A;
}