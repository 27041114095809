@media all and (min-width: 1300px) {
  .container {
    max-width: 1240px;
  }
}

// Flex
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.border-width-2px {
  border-width: 2px !important;
}

.wrapper {
  // display: flex;
  width: 100%;
}

// Widths
.w-200px {
  width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}
