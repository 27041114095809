.vbt-autcomplete-list {
	padding-top: 1px !important;
	&.shadow {
		box-shadow: 0 0 0 0 transparent !important;
	}
	a {
		&.list-group-item {
			padding: 8px 15px;
			font-size: 13px;
			border-width: 1px !important;
		}
	}
}
