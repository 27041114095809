.bddot-slider-1 {
	display: block !important;
	.slider-horizontal {
		width: 100%;
	}
	.slider-track {
		background-image: unset;
		background: $pacific;
		.slider-selection {
			background-image: unset;
			background: $warning;
		}
	}
	.slider-handle {
		background-color: $primary;
		background-image: unset;
	}
}
