/* 
// Footer Styles
*/
/* Footer */
.ftr-top {
	background: #f4f5f6;
	padding: 3.75em 0 3em 0;
	p {
		color: #444;
	}
}
.ftr-title01 {
	font-size: 1em;
}
.ftr-title01 img {
	max-width: 3.75em;
	margin-right: 0.75em;
}
.ftr-title01 b {
	font-family: "Play";
	font-size: rem(25px);
	font-weight: bold;
	color: #0e477a;
	text-transform: uppercase;
	vertical-align: middle;
}
.ftr-title01.mt {
	margin-top: 10px;
}
.ftr-btm {
	position: relative;
	background: #343d3d;
	padding: rem(5px) 0;
}
.ftr-btm p {
	font-family: "Play";
	color: #fff;
	line-height: 2.6em;
	margin: 0;
	@include media-breakpoint-down(sm) {
		font-size: rem(14px);
	}
}
.ftr-btm span {
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
}
.ftr-btm span a {
	display: block;
	background: #04f0fc;
	color: #fff;
	width: 52px;
	height: 52px;
	text-align: center;
	i {
		font-size: 2em;
		margin-top: 10px;
	}
	@media all and (max-width: 767px) {
		height: 46px;
		i {
			margin-top: 5px;
		}
	}
}
.span02 {
	display: block;
	max-width: 345px;
	margin-bottom: 1.875em;
}
.span02 input {
	background: #fff;
	font-family: "Play-Bold";
	font-size: 1.25em;
	color: #c2c6c9;
	width: 100%;
	height: 3.5em;
	line-height: 3.5em;
	border: none;
	box-shadow: none;
	padding: 0 0.5em;
}
.span03 {
	display: block;
	max-width: 250px;
	margin-bottom: 20px;
}
.span03 a {
	display: block;
	background: #fff;
	font-family: "Play-Bold";
	text-align: center;
	font-size: 1.5em;
	color: #04f0fc;
	line-height: 2.6666em;
	border-radius: 5px;
	border: 3px solid #04f0fc;
}

.mb-main-menu {
	display: none;
}
