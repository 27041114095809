.ul-days {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid $light;
  flex-wrap: wrap;
}
.ul-days li {
  display: inline-flex;
  justify-content: center;
  font-size: 16px;
  padding: 10px 12px;
  width: 14%;
}

.ul-date {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.ul-date li {
  display: inline-flex;
  text-align: center;
  font-size: 16px;
  color: $secondary;
  justify-content: center;
  align-items: center;
  width: 14%;
  padding: 20px 12px;
  position: relative;
  &:nth-child(7n):after {
    flex-basis: 100%;
  }
  span.dot {
    width: 6px;
    height: 6px;
    display: inline-block;
    position: absolute;
    bottom: 8px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.ul-date li.disable {
  color: #cacbcc;
}
.ul-date li em {
  font-style: normal;
  width: 46px;
  height: 46px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ul-date li b {
  font-weight: normal;
  border: 1px solid $primary;
  width: 46px;
  height: 46px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointment-calendar .vdp-datepicker__calendar .cell {
  height: 60px;
  line-height: 60px;
  border-radius: 60px;
}
