.chat-bubble {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 28px;
  padding: 0 10px;
  border-radius: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 6px 2px rgba(112, 112, 112, 0.25);
  cursor: pointer;
  .chat-sign {
    background: $light-yellow;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: "";
      position: absolute;
      right: 10px;
      top: 42px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 10px solid #fff5cc;
      border-right: 0px;
      transform: rotate(46deg);
    }
    img {
      width: 21px;
    }
  }
  span {
    text-transform: uppercase;
    display: inline-flex;
    margin-left: 10px;
    font-size: 14px;
    line-height: 1em;
  }
}

.chat-window {
  position: fixed;
  right: 20px;
  bottom: 65px;
  width: 320px;
  height: 430px;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.2);
  z-index: z("chat-window");
  background: white;
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .chat-window {
    &-header {
      padding: 15px 0px 15px 20px;
      background: $blue;
      position: relative;
    }
    &-body {
      // padding: 15px 20px;
      flex-grow: 1;
    }
  }

  &-minimized {
    height: 52px;
    position: fixed;
    right: 20px;
    bottom: 100px;
    width: 320px;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.2);
    z-index: z("chat-window");
    background: white;
    border-radius: $border-radius;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
}

.chat-minimized {
  width: 100%;
  padding-right: 52px;
}

.chat-minimizer {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  background: $primary;
  padding: 0 15px;
  display: flex;
  align-items: center;
  i {
    font-size: 22px;
  }
}

.logo-chat {
  width: 30px;
}

.chat-msg-input {
  border-top: 1px solid $light;
  .form-control {
    border: 0px;
    height: 51px;
    &::placeholder {
      color: $gray;
    }
  }
  .input-group-append {
    .btn {
      width: 51px;
      padding: 0px;
    }
  }
}

.chat {
  &-conversation {
    flex-direction: column-reverse;
    height: 235px;
    overflow-y: auto;
    padding: 15px 20px;
    .msg-agent {
      margin-left: 10px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid $light;
        border-left: 0px;
      }
    }
    .user-reply {
      width: calc(100% - 40px);
    }
  }
}
.chat-notification {
  position: relative;
  .count {
    position: absolute;
    right: -7px;
    top: 0px;
    background: $danger;
    color: white;
    font-size: 9px;
    line-height: 1;
    padding: 1px 3px;
  }
}

.agent-typing {
  .dot-flashing {
    margin-left: 15px;
    position: relative;
    color: $tartiary;
    background: $tartiary;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    animation: dotFlashing 1.5s infinite linear alternate;
    animation-delay: 0.5s;
    &:before,
    &:after {
      width: 7px;
      height: 7px;
      border-radius: 5px;
      color: $tartiary;
      background: $tartiary;
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
    }
    &:before {
      left: -15px;
      animation: dotFlashing 1.5s infinite alternate;
      animation-delay: 0s;
    }
    &:after {
      left: 15px;
      animation: dotFlashing 1.5s infinite alternate;
      animation-delay: 1s;
    }
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $tartiary;
  }
  50%,
  100% {
    background-color: #f2f2f2;
  }
}
