//
// tags styling
//
.tag-video-visit {
  display: flex;
  align-items: stretch;
  line-height: 1em;
  .tag {
    padding: 3px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      border-radius: 0 0 0 10px;
      i {
        font-size: 16px;
        color: white;
      }
    }
    &:last-child {
      border-radius: 0 10px 0 0;
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}
