.sidebarToggle {
  display: inline-flex;
  width: 50px;
  height: 50px;
  a {
    display: flex;
    height: 100%;
    width: 100%;
    color: white;
    align-items: center;
    justify-content: center;
    background: $secondary;
    i {
      font-size: 25px;
    }
  }
}
.sidebar {
  width: 50px;
  min-height: 100vh;
  position: fixed;
  top: 91px;
  left: 0;
  z-index: z("sidebar");
  background-color: $pacific;
  transition: width 0.3s;
  ul {
    padding: 16px 0;
    li {
      text-align: left;
      transition: 0s 0.3s;
      a {
        padding: 13px 0;
        display: block;
        height: 100%;
        color: rgba($info, 0.6);
        padding-left: 13px;
        i {
          width: 20px;
          text-align: center;
        }
        span.nav-title {
          opacity: 0;
          font-size: 0px;
          height: 0px;
          width: 0px;
          transition: height 0s;
        }
        &.active {
          background: $blueLagoon;
          color: white;
        }
      }
    }
  }
  &.expanded {
    width: 250px;
    ul {
      li {
        text-align: left;
        a {
          span.nav-title {
            opacity: 1;
            font-size: 16px;
            height: auto;
            width: auto;
            transition: font-size 0s 0.3s;
          }
        }
      }
    }
  }
  @media all and (max-width: 640px) {
    top: 50px;
  }
}
.content {
  margin-left: 25px;
  @media all and (max-width: 640px) {
    margin-top: 8px;
  }
}
