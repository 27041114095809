/* 
// Buttons
*/
.btn{
    // &:focus, &:active, &:not(:disabled):not(.disabled):active{
    //     box-shadow: 0 0 0 0 transparent !important;
    // }
    border-width: 1px;
}
.btn-lg{
    font-family: 'Play';
    font-weight: bold;
}
.btn-xl{
    padding: rem(25px) rem(60px);
    line-height: 1em;
    font-family: 'Play';
    font-weight: bold;
    font-size: rem(30px);
    color: $primary;
    border-radius: rem(8px);
}
[class*="btn-outline-"]{
    border-width: 2px;
    border-style: solid;
}