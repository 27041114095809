.popup {
	.wrap {
		padding: 0px 15px 0px;

		#content {
			margin-top: 5px;
		}
	}
	.alert-dock {
		bottom: 0px;
		margin-left: 0px;
	}
}
#cboxClose {
	bottom: unset;
	top: 0;
}

#cboxLoadedContent {
	margin-bottom: 0px;
}
.booking-modal {
	.form-control {
		margin-bottom: rem(15px);
	}
}
.modal {
	.modal-content {
		border-radius: 10px;
	}
	.modal-header {
		padding: 10px 15px;
	}
}
