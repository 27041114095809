.social-share-icons {
	a {
		border-color: $gray !important;
		color: $gray;
		&:hover {
			border-color: $primary !important;
			color: $primary;
		}
	}
}
