// Doctor Listing
.doc-listing-thumb {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  .thumb-img {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    border: 1px solid $gray;
    padding: 5px;
    span {
      background: $gray;
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: stretch;
      justify-content: center;
      overflow: hidden;
      border-radius: 100%;
      img {
        &:after {
          font-size: 16px;
          color: rgb(100, 100, 100);
          display: flex;
          align-items: center;
          justify-content: center;
          background: $gray;
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 25px);
          height: calc(100% - 10px);
          margin: 5px;
          border-radius: 100%;
        }
      }
      &.propic-circle {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: $gray;
        overflow: hidden;
        position: relative;
        img {
          &:after {
            content: "\e919"" " attr(alt);
            font-family: "icomoon";
            font-size: 16px;
            color: rgb(100, 100, 100);
            display: flex;
            align-items: center;
            justify-content: center;
            background: $gray;
            position: absolute;
            top: 0px;
            left: 0px;
            border-radius: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  button {
    position: absolute;
    bottom: 0px;
    font-size: 10px;
  }
}
