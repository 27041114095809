/* 
  Rotate
*/
$rotate: (45, 90, 135, 180, 225, 270, 315, 360);

@mixin rotate-variation($parent, $value) {
	#{$parent} {
		transform: rotate(#{$value}deg);
		display: inline-block;
	}
}
@each $value in $rotate {
	@include rotate-variation(".rotate-#{$value}", $value);
}
